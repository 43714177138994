// 菜单3贸易
<template>
  <div class="main">
    <div class="top">
      <div class="top-text">TRADE AND COMMERCIAL</div>
    </div>
    <div class="part3">
      <div class="part-right">
        <img
          class="part-right-img"
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsHZImg/pc/sysImgV1/tc_bg_tra@2x.png"
        />
      </div>
      <div class="part-left">
        <div class="part-title">
          <div class="part-line"></div>
          <div class="part-name">TRADE BLINDS</div>
        </div>
        <div class="part-text">
          No matter the size of your business, you will benefit from becoming a
          CINDRA trade customer. You’ll not only enjoy competitive pricing, but
          you’ll also be the first to hear about new designs and collections.
          Furthermore, you’ll have access to our online portal for easy
          ordering, as well as our dedicated customer service department.
        </div>
        <div class="part-text">
          When it comes to your own customers, we understand the time pressures
          of delivering quickly. By manufacturing at our own factory in Ningbo,
          China, we are able to provide fast turnover and short delivery times.
          We can also offer you a variety of high quality swatch packs and
          sample books, allowing your customers to get a feel for what they’re
          purchasing, as well as supplying blinds, curtains and other products
          for your showroom.
        </div>
        <!-- <div class="part-btn btn black-hover" @click="toContactUs()">
          CONTACT FORM<span class="swiper-text">></span>
        </div> -->
      </div>
    </div>
    <div class="part2">
      <div class="part-left">
        <div class="part-title">
          <div class="part-line"></div>
          <div class="part-name">COMMERCIAL BLINDS</div>
        </div>
        <div class="part-text">
          Our purchasing power and Chinese manufacturing make us an ideal
          partner for renovation and decoration projects. Backed by years of
          industry experience, we bring a wide range of benefits to your
          windows, your space and your workforce. We work with a number of
          businesses and institutions on large-scale installations, including
          offices, hotels, schools, council buildings and more.
        </div>
        <div class="part-text">
          Our capacity and agility enable us to service a variety of sectors,
          delivering on short lead times with high performance products. By
          manufacturing in-house, we remove the middleman and are able to offer
          a wealth of commercial benefits to our clients.
        </div>
        <div class="part-text">
          Our expert team will help to identify the right product solutions to
          best meet your requirements, then ensure the process runs as smoothly
          as possible. For all commercial projects, we provide a quotation upon
          request.
        </div>
        <!-- <div class="part-btn btn black-hover" @click="toContactUs()">
          CONTACT FORM<span class="swiper-text">></span>
        </div> -->
      </div>
      <div class="part-right">
        <img
          class="part-right-img"
          src="https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsHZImg/pc/sysImgV1/tc_bg_com@2x.png"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { usVisitLogInsert } from "@/api/home.js";
export default {
  name: "Trade",
  data() {
    return {
      ossPrefix: "https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsImg/",
    };
  },
  activated() {
    // console.log("activated");
    this.addVisitLogInsert();
  },
  methods: {
    addVisitLogInsert() {
      let website = window.location.href;
      let websiteTitle = "T & C";
      // console.log(website, websiteTitle);
      usVisitLogInsert(website, websiteTitle, 0).then(() => {});
    },
  },
};
</script>
<style scoped>
.main {
  width: 100%;
  box-sizing: border-box;
}
.top {
  width: 1920px;
  height: 680px;
  margin: 0 0 58px;
  background-image: url("https://blhc806.oss-cn-hangzhou.aliyuncs.com/wmUsHZImg/pc/sysImgV1/tc_bg_png@2x.png");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.top-text {
  width: 460px;
  height: 120px;
  font-weight: bold;
  font-size: 34px;
  color: #fff;
  background-color: #475c5e;
  opacity: 0.9;
  text-align: center;
  line-height: 120px;
}
.content {
  width: 1110px;
  margin: 0 auto 21px;
  font-size: 16px;
  color: #262626;
}
.part2 {
  width: 1110px;
  margin: 0 auto;
  padding: 56px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.part-left {
  width: 625px;
  height: 445px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}
.btn {
  width: 290px;
  box-sizing: border-box;
}
.part-right {
  width: 445px;
  height: 445px;
  position: relative;
}
.part-right-img {
  width: 445px;
  height: 445px;
}
.part-right-icon1,
.part-right-icon2 {
  width: 200px;
  height: 110px;
  position: absolute;
  bottom: 20px;
}
.part-right-icon1 {
  left: 16px;
}
.part-right-icon2 {
  right: 16px;
}

.part3 {
  width: 1920px;
  margin: 0 auto;
  padding: 56px 405px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
}
.part-right-icon3,
.part-right-icon4 {
  width: 170px;
  height: 84px;
  position: absolute;
  right: 20px;
}
.part-right-icon3 {
  bottom: 119px;
}
.part-right-icon4 {
  bottom: 14px;
}
.white-hover:hover {
  background-color: #fff;
  color: #293744;
}
.black-hover:hover {
  background-color: #5f6467;
  color: #fff;
}
.black-hover-border:hover {
  background-color: #5f6467;
  color: #fff;
  border: 1px solid #5f6467;
}
.part-title {
  display: flex;
  align-items: flex-end;
  margin-bottom: 21px;
}
.part-line {
  width: 1px;
  height: 100px;
  background-color: #293744;
}
.part-name {
  font-size: 34px;
  font-weight: bold;
  color: #262626;
  margin: 0 0 8px 18px;
}
.part-text {
  font-size: 16px;
  color: #262626;
  margin: 0 5px 15px 18px;
}
</style>
